import { useMemo } from 'react';
import PropTypes from 'prop-types';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider as MUIThemeProvider, ThemeOptions } from '@mui/material/styles';
import { palette } from './palette';
import { shadows } from './shadows';
import { overrides } from './overrides';
import { typography } from './typography';
import { customShadows } from './custom-shadows';
import React from 'react';

// ----------------------------------------------------------------------

export default function ThemeProvider({ children }: any) {
  const memoizedValue = useMemo(
    () =>
      ({
        palette: palette(),
        typography,
        shadows: shadows(),
        // TODO: not able to add customShadows in TS
        // customShadows: customShadows(),
        shape: { borderRadius: 8 }
      } as ThemeOptions),
    []
  );

  // TODO: why memoizing this ?
  const theme = createTheme(memoizedValue);
  theme.components = overrides(theme);

  return (
    <MUIThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </MUIThemeProvider>
  );
}

ThemeProvider.propTypes = {
  children: PropTypes.node
};
