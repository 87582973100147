import SvgColor from './../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name: string) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'dashboard',
    path: '/',
    icon: icon('ic_analytics')
  },
  {
    title: 'Usuarios',
    path: '/users',
    icon: icon('ic_user')
  },
  {
    title: 'Questionarios',
    path: '/questionnaires',
    icon: icon('ic_cart')
  },
  {
    title: 'Questionario Actual',
    path: '/currentquestionnaire',
    icon: icon('ic_blog')
  },
  {
    title: 'Evaluaciones',
    path: '/evaluations',
    icon: icon('ic_lock')
  },
  {
    title: 'admin',
    path: '/admin',
    icon: icon('ic_lock')
  }
];

export default navConfig;
