const queryString = (object: any) => {
  if (!object) return '';

  let qs = '';
  let firstParam = true;
  const params = Object.keys(object);
  for (const key of params) {
    if (!object[key] || object[key] === '') continue;

    qs += firstParam ? '?' : '&';
    qs += `${key}=${object[key]}`;
    if (firstParam) firstParam = false;
  }

  return qs;
};

const calls = [
  {
    name: 'getCurrentQuestionnaire',
    build: () => ({
      options: {
        method: 'GET'
      },
      endpoint: `questionnaire/current`
    })
  },
  {
    name: 'getCurrentQuestionnaireAnswers',
    build: () => ({
      options: {
        method: 'GET'
      },
      endpoint: `questionnaire/current/answers`
    })
  },

  {
    name: 'getAllQuestionnaires',
    build: () => ({
      options: {
        method: 'GET'
      },
      endpoint: `questionnaire/current`
    })
  },

  {
    name: 'getQuestionnaire',
    build: (id: number) => ({
      options: {
        method: 'GET'
      },
      endpoint: `questionnaires/${id}`
    })
  }
];

export default calls;
