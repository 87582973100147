import { lazy, Suspense } from 'react';
import { Navigate, Outlet, useRoutes } from 'react-router-dom';
import DashboardLayout from './layouts/dashboard';
import ThemeProvider from './theme';
import CurrentQuestionnaireAnswersPage from './sections/curent-questionnaire-answers/view/current-questionnaire-answers-view';

export const DasboardPage = lazy(() => import('./pages/dashboard'));
export const UsersPage = lazy(() => import('./pages/users'));
export const QuestionnairesPage = lazy(() => import('./pages/questionnaires'));
export const QuestionnairePage = lazy(() => import('./pages/questionnaire'));
export const EvaluationsPage = lazy(() => import('./pages/login'));
export const CurrentQuestionnaire = lazy(() => import('./pages/current-questionnaire'));
export const Page404 = lazy(() => import('./pages/page-not-found'));

export default function App() {
  const routes = useRoutes([
    {
      element: (
        <DashboardLayout>
          <Suspense>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      ),
      children: [
        // { element: <DasboardPage />, index: true },
        // TODO: Quitar estas tres lieas de abajo cuando este listo el contexto para todas las paginas incluso las que esten fuera de App.tsx
        {
          index: true,
          // path: 'currentquestionnaire',
          element: <CurrentQuestionnaire />
        },
        { path: 'admin', element: <CurrentQuestionnaireAnswersPage /> },
        { path: 'users', element: <UsersPage /> },
        {
          path: 'questionnaires',
          element: <QuestionnairesPage />
        },
        {
          path: 'questionnaire',
          element: <QuestionnairePage />
        }
      ]
    },
    {
      path: 'currentquestionnaire',
      element: <CurrentQuestionnaire />
    },
    {
      path: '404',
      element: <Page404 />
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />
    }
  ]);

  return <ThemeProvider>{routes}</ThemeProvider>;
}
