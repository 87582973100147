// ----------------------------------------------------------------------

interface Account {
  displayName: string;
  email: string;
  photoURL: string;
  role?: string;
}

export const account: Account = {
  displayName: 'Elier Lopez',
  role: "Maestro",
  email: 'me@elierlopez.net',
  photoURL: '/assets/images/avatars/avatar_25.jpg'
};
