import { useContext, useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import Container from '@mui/material/Container';
import TableBody from '@mui/material/TableBody';
import Typography from '@mui/material/Typography';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';

import { users } from '../../../_mock/user';

import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';

import TableNoData from '../table-no-data';
import UserTableRow from '../table-row';
import QuestionnaireTableHead from '../table-head';
import TableEmptyRows from '../table-empty-rows';
import TableToolbar from '../table-toolbar';
import { emptyRows, applyFilter, getComparator } from '../../../utils/tableUtils';
import { AppContext } from '../../../context-provider';
import '../styles.css';
import { Skeleton } from '@mui/material';

// ----------------------------------------------------------------------

export default function CurrentQuestionnaireAnswersPage() {
  const { getCurrentQuestionnaireAnswers, currentQuestionnaireAnswers } = useContext(AppContext);

  useEffect(() => {
    if (!currentQuestionnaireAnswers) getCurrentQuestionnaireAnswers();
  }, []);

  return (
    <>
      {currentQuestionnaireAnswers ? (
        <OrderedQuestionnaire questionnaire={currentQuestionnaireAnswers} />
      ) : (
        <>
          <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
          <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
          <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
          <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
          <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
        </>
      )}
    </>
  );
}

const OrderedQuestionnaire: React.FC<any> = ({ questionnaire }) => {
  return (
    <div className="wrapper">
      <h1 style={{ textAlign: 'center' }}>{questionnaire?.title}</h1>
      <ol role="list" className="cq">
        {questionnaire?.questions.map((q: any) => (
          <li className="cq" key={q.id} onClick={()=>{alert(q.answer)}}>
            {q.questionSentence}
          </li>
        ))}
      </ol>
    </div>
  );
};
