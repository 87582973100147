import axios, { InternalAxiosRequestConfig } from 'axios';
import calls from './calls';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: { Pragma: 'no-cache' }
});

const buildAPICall = (call: any) => {
  const { build } = call;

  return (params: any, options: any) => {
    const { options: fetchOptions, endpoint } = build(params, options);
    return api(endpoint, fetchOptions);
  };
};

export interface HttpApi {
  [key: string]: any;
}
export const generateAPI = () => {
  const api: HttpApi = {};

  calls.forEach(call => {
    api[call.name] = buildAPICall(call);
  });
  api['getToken'] = addLogInCall();
  addInterceptors();

  return api;
};

const addLogInCall = () => {
  const logInCall = {
    build: (userCreds: any) => ({
      options: {
        method: 'GET',
        headers: userCreds
      },
      endpoint: 'authorization'
    })
  };
  return (params: any) => {
    const { options: fetchOptions, endpoint } = logInCall.build(params);
    return api(endpoint, fetchOptions);
  };
};

const addInterceptors = () => {
  api.interceptors.request.use(
    (config: InternalAxiosRequestConfig) => {
      return new Promise(resolve => {
        // Add authorization header to request if there is a stored token
        const saved = localStorage.getItem('authUser');
        if (saved && config && config.headers) {
          const authUser = JSON.parse(saved);
          config.headers.Authorization = `Bearer ${authUser.token}`;
        }
        resolve(config);
      });
    },
    error => {
      return Promise.reject(error);
    }
  );

  api.interceptors.response.use(
    response => {
      return response;
    },
    error => {
      return Promise.reject(error);
    }
  );
};

export default generateAPI();
