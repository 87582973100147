import Box from '@mui/material/Box';

import { useResponsive } from './../../hooks/use-responsive';

import { NAV, HEADER } from './config-layout';
import { FC, ReactNode } from 'react';

// ----------------------------------------------------------------------

const SPACING = 8;
interface MainProps {
  children: ReactNode;
  sx?: any;
}

const Main: FC<MainProps> = ({ children, sx, ...other }) => {
  const lgUp = useResponsive('up', 'lg', undefined);

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        minHeight: 1,
        display: 'flex',
        flexDirection: 'column',
        py: `${HEADER.H_MOBILE + SPACING}px`,
        ...(lgUp && {
          px: 2,
          py: `${HEADER.H_DESKTOP + SPACING}px`,
          width: `calc(100% - ${NAV.WIDTH}px)`
        }),
        ...sx
      }}
      {...other}
    >
      {children}
    </Box>
  );
};

export default Main;
