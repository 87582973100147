import { FC, forwardRef } from 'react';
import { Link } from 'react-router-dom';

// ----------------------------------------------------------------------

interface RouterLinkProps {
  href: string;
}

const RouterLink: FC<RouterLinkProps> = forwardRef(({ href, ...other }, ref: any) => <Link ref={ref} to={href} {...other} />);

export default RouterLink;
