import api from '../api';
import { Questionnaire } from '../types';
import { FC, ReactNode, createContext, useState } from 'react';

interface AppContextType {
  getCurrentQuestionnaire: () => void;
  currentQuestionnaire?: Questionnaire;
  getCurrentQuestionnaireAnswers: () => void;
  currentQuestionnaireAnswers?: Questionnaire;
}

export const AppContext = createContext({} as AppContextType);
export const AppContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [currentQuestionnaire, setCurrentQuestionnaire] = useState<Questionnaire | undefined>(undefined);
  const [currentQuestionnaireAnswers, setCurrentQuestionnaireAnswers] = useState<Questionnaire | undefined>(undefined);
  const getCurrentQuestionnaire = () => {
    api
      .getCurrentQuestionnaire()
      .then((resp: any) => {
        setCurrentQuestionnaire(resp.data);
      })
      .catch((err: any) => {
        console.error(err);
      });
  };
  const getCurrentQuestionnaireAnswers = () => {
    api
      .getCurrentQuestionnaireAnswers()
      .then((resp: any) => {
        setCurrentQuestionnaireAnswers(resp.data);
      })
      .catch((err: any) => {
        console.error(err);
      });
  };

  return (
    <AppContext.Provider value={{ currentQuestionnaire, getCurrentQuestionnaire, currentQuestionnaireAnswers, getCurrentQuestionnaireAnswers }}>{children}</AppContext.Provider>
  );
};
